<template>
  <div>
    <v-container fluid style="padding-bottom: 10vh;">
      <div v-if="!user.data">
        <Menubar />
      </div>
      <div v-if="user.data">
        <UserMenu
          v-if="user.data.rolle == 'Trainer' || user.data.rolle == 'Vorstand'"
        />
        <ZuschauerMenu v-if="user.data.rolle == 'Zuschauer'" />
        <SchiriMenu v-if="user.data.rolle == 'Schiedsrichter'" />
      </div>
      <v-main>
        <v-row justify="center">
          <v-col cols="12" md="8" lg="6">
            <h1 class="my-3">
              Release Version 4.0
              <small>(26. November 2021)</small>
            </h1>
            <h2 class="mt-5 mb-3">
              Neue Funktionen:
            </h2>
            <ul>
              <li>
                <b>
                  2G und 2G-PLUS FUNKTIONEN IN ALLEN BEREICHEN
                  <small>
                    Ggf. fehlen auf einzelnen Seiten oder exporten diese
                    Funktionen noch. Diese werden mit einem Update aber Zeitnah
                    ergänzt!
                  </small>
                </b>
              </li>
            </ul>
            <h1 class="my-3">
              Release Version 3.7
              <small>(28. Juli 2021)</small>
            </h1>
            <h2 class="mt-5 mb-3">
              Neue Funktionen:
            </h2>
            <ul>
              <!-- <li>
                <b>SCHNITTSTELLE FÜR GESUNDHEITSÄMTER</b>
                <br />
                Es wurde eine sichere Schnittstelle für Gesundheitsämter
                geschaffen. Die App ist nun dank des IRIS-Systems in der Lage
                seine Daten verschlüsselt an Gesundheitsämter zu übertragen. Und
                zwar erst nach Erlaubnis des jeweiligen Vereins. Weitere
                Informationen findest Du in der Anleitung.
                <br />
              </li> -->
              <li>
                <b>
                  Versammlungen können nun über mehrere Tage festgelegt werden
                </b>
              </li>
              <li>
                <b>
                  Über die Zuschauerkontrolle wird eine Warnung ausgegeben, wenn
                  man eine Person doppelt zur Anwenheitsliste hinzufügen möchte.
                </b>
              </li>
              <li>
                <b>
                  In der Zuschauerkontrolle wurde die Suchfunktion überarbeitet
                  und eine Filterfunktion ergänzt.
                </b>
              </li>
              <li
                v-if="
                  template.var.verband == 'FLVW' ||
                  template.var.verband == 'FVM'
                "
              >
                <b>
                  Mailversand für Anmeldungen bei Wettkämpfen: Es wird 1 Tag vor
                  Wettkampfbeginn eine Erinnerungsmail verschickt inkl.
                  CheckIn-Link
                </b>
              </li>
            </ul>
            <h1 class="my-3">
              Release Version 3.6
              <small>(07. Juli 2021)</small>
            </h1>
            <h2 class="mt-5 mb-3">
              Neue Funktionen:
            </h2>
            <ul>
              <li>
                <b>Spiele innerhalb eines Vereins</b>
                <br />
                Es ist nun möglich, dass man innerhalb des Vereins Spiele
                anlegen kann. Der Link für den Gastverein kann nun auch
                innerhalb des Vereins aufgerufen werden.
                <br />
              </li>
            </ul>
            <h1 class="my-3">
              Release Version 3.5
              <small>(02. Juli 2021)</small>
            </h1>
            <h2 class="mt-5 mb-3">
              Neue Funktionen:
            </h2>
            <ul>
              <li>
                <b>Testpflicht (3G-Regel)</b>
                <br />
                In den Einstellungen des Hygienekonzeptes kann nun "Testpflicht"
                aktiviert werden. Dies sorgt dafür, dass man vor einem CheckIn
                bestätigen muss, dass man Getestet, Geimpft oder Genesen ist.
                Für Team-Mitglieder kann eine Impfung oder Genesung dauerhaft
                hinterlegt werden, sodass ein Trainer in seiner
                Anwesenheitsliste nur noch Team-Mitglieder ohne Impfung bzw.
                ohne Genesung als negativ getestet abhaken muss.
                <br />
              </li>
              <li v-if="template.var.verband == 'FVM'">
                <b>Wettkampfmodul</b>
                Es gibt nun die Möglichkeit einen Wettkampf (z.B. in für
                Leichtathletik-Abteilungen) anzulegen.
              </li>
            </ul>
            <h2 class="mt-5 mb-3" v-if="template.var.verband == 'FLVW'">
              Fixes (Fehlerbehebungen):
            </h2>
            <ul v-if="template.var.verband == 'FLVW'">
              <li>
                - Div. Fehlerbehebungen (im Wettkampfmodul)
              </li>
            </ul>
            <h1 class="my-3">
              Release Version 3.4
              <small>(27. Mai 2021)</small>
            </h1>
            <h2 class="mt-5 mb-3">
              Neue Funktionen:
            </h2>
            <ul>
              <li>
                <b>CheckIn-Timer</b>
                <br />
                Nach dem CheckIn ist nun ein Timer sichtbar, der die bisher
                vergangene Zeit der Anwesenheit anzeigt.
                <br />
              </li>
              <li>
                <b>
                  Anzahl der anwesenden Sportler & Trainer bei
                  "Team-Anwesenheit"
                </b>
                <br />
                Es wird nun die Anzahl der anwesenden ( = abgehakten) Sportler &
                Trainer in der Team-Anwesenheit angezeigt
                <br />
              </li>
              <li>
                <b>Überarbeitung der Anwesenheitslisten-Generierung</b>
                <br />
                Die Generierung der Anwesenheitslisten wurde überarbeitet. Es
                wird nun nicht mehr nur nach Veranstaltung und Aktivität
                gefiltert, sondern auf der Anwesenheitsliste tauchen immer alle
                Aktivitäten auf und alle Personen die zum gleichen Zeitpunkt auf
                der Sportstätte anwesend waren.
                <br />
              </li>
            </ul>
            <h2 class="mt-5 mb-3">Fixes (Fehlerbehebungen):</h2>
            <ul>
              <li>
                - Div. Fehlerbehebungen
              </li>
            </ul>
            <h1 class="my-3">
              Release Version 3.3
              <small>(20. Mai 2021)</small>
            </h1>
            <h2 class="mt-5 mb-3">
              Neue Funktionen:
            </h2>
            <ul>
              <li>
                <b>Eigene Aktivitäten anlegen</b>
                <br />
                - Es ist nun über die Vereinsverwaltung möglich eigene
                Aktivitäten für Anwesenheiten zu erstellen. Beim CheckIn und in
                der Zuschauerkontrolle wird es dann eine Auswahl geben für die
                Aktivitäten (z.B. neben den Standard-Aktivitäten wie
                "Zuschauer", "Sportler" oder "Trainer" nun auch eigene
                Aktivitäten wie z.B. "Presse", "Fotograf", "Physio", usw.)
                <br />
              </li>
              <li>
                <b>
                  Änderungen Datenschutz für die Speicherung von dauerhaft
                  gespeicherten Personen bzw. Zuschauern
                </b>
                <br />
                Vor jedem dauerhaften Speichern, muss die jeweilige Person nun
                mit einer Unterschrift bestätigen, dass sie der
                Datenschutzerklärung und der Verarbeitung der Daten zustimmmt.
                Dazu gibt es nun ein digitales Unterschriftenfeld, sodas auch
                für diesen Schritt kein Papier notwending ist.
                <br />
              </li>
              <li>
                <b>Saison-Übergang (Spieler in andere Teams verschieben)</b>
                <br />
                In der Teamverwaltung gibt es nun die Option mehrere Spieler
                auszuwählen, um sie dann gesammelt in ein anderes Team zu
                verschieben oder komplett zu löschen. Diese Funktion soll vor
                allem den Übergang in die neue Saison erleichtern.
                <br />
              </li>
            </ul>
            <h2 class="mt-5 mb-3">Fixes (Fehlerbehebungen):</h2>
            <ul>
              <li>
                Redirect Problem bei Gastmannschaften aus anderen Verbänden
              </li>
              <li v-if="template.var.verband == 'FLVW'">
                Fehlerbehebung in der Wettkampfkontrolle: Es konnte nicht nach
                Anmeldungen gesucht werden, wenn einer Anmeldung keine
                Startnummer zugewiesen wurde.
              </li>
            </ul>
            <h1 class="my-3">
              Release Version 3.2
              <small>(19. APRIL 2021)</small>
            </h1>
            <h2 class="mt-5 mb-3" v-if="template.var.verband == 'FLVW'">
              Neue Funktionen:
            </h2>
            <ul>
              <li>
                <b>"WETTKAMPF-MODUL":</b>
                <br />
                - Voranmeldung für Athleten, Trainer & Co. nun über einen
                speziellen Link möglich. - Getrennter Sportler-CheckIn
                <br />
              </li>
            </ul>
            <h2 class="mt-5 mb-3">Fixes (Fehlerbehebungen):</h2>
            <ul>
              <li>
                Anpassung "Anmelden mit Google"
              </li>
              <li>
                Fehlerbehebung bei Layout von QR-Code Plakaten
              </li>
            </ul>
            <div v-if="template.var.verband != 'BFV'">
              <v-divider class="my-5"></v-divider>
              <h1 class="my-3">
                Release Version 3.1
                <small>(28. März 2021)</small>
              </h1>
              <h2 class="mt-5 mb-3">Neue Funktionen:</h2>
              <ul>
                <li>
                  <b>"Gespeicherte Zuschauer":</b>
                  <br />
                  - Vorstände können dauerhaft gespeicherte Personen löschen.
                  <br />
                  - Vorstände können Personen erstellen, die in der
                  Zuschauerkontrolle als "Vereins-Person" erfasst werden können.
                  <br />
                  (Zu finden via "Verein" -> "Gespeicherte Zuschauer").
                </li>
                <li>
                  <b>Serien-Termine:</b>
                  <br />
                  - Serien-Termine können nun bearbeitet und gelöscht werden.
                  <br />
                  Wichtig: Diese Funktion ist nur für neu angelegte
                  Serientermine möglich. Vor diesem Release angelegte
                  Veranstaltungen können nach wie vor nur über die
                  "Veranstaltungen entfernen"-Funktion entfernt werden.
                </li>
                <li>
                  <b>Gast-Sportler:</b>
                  <br />
                  - Es ist nun möglich Gast-Sportler aus anderen Mannschaften
                  des Vereins hinzuzufügen.
                </li>
                <li>
                  <b>Anwesenheitsliste & Team:</b>
                  Trainer und Betreuer können nun separat erfasst werden und
                  tauchen in der Anwesenheitsliste nun nicht mehr als Sportler
                  auf, sondern als "Trainer" bzw. "Betreuer".
                </li>
                <li>
                  <b>Team-Übersicht:</b>
                  Über "Person suchen" können Vorstände nun nach einer Person in
                  den angelegten Mannschaften suchen.
                </li>
              </ul>
              <div v-if="template.var.verband != 'FVM'">
                <v-divider class="my-5"></v-divider>
                <h1 class="my-3">
                  Release Version 3.0
                  <small>(06. Januar 2021)</small>
                </h1>
                <h2 class="mt-5 mb-3">Neue Funktionen:</h2>
                <ul>
                  <li>
                    Abteilungen: Es können nun Abteilungen erstellt werden und
                    Teams können Abteilungen zugewiesen werden. Zu Verwalten
                    unter "Verein" -> "Abteilungen verwalten"
                  </li>
                  <li>
                    Teams:
                    <br />
                    - Ein Team kann einer Abteilung zugewiesen werden (Im Team:
                    "Optionen" -> "Team bearbeiten" )
                    <br />
                    - Der Name eines Teams kann jetzt auch endlich verändert
                    werden. Das Team muss somit nicht mehr gelöscht werden, um
                    es umzubenennen.
                  </li>
                  <li>
                    Übersicht Veranstaltungen:
                    <br />
                    - Verschiedene Filter-Möglichkeiten: Neben Abteilungen kann
                    man auch nach Team, Veranstaltungsart und Sportstätte
                    filtern.
                  </li>
                  <li>
                    Zuschauer-Bereich - InApp QR-Code Scanner: Man kann nun als
                    eingeloggter Zuschauer unter dem Menüpunkt "QR-Code Scan"
                    direkt einen QR-Code scannen um schneller bei einem Verein
                    einchecken zu können.
                  </li>
                  <li>
                    Mail-Versand: - Nach Registrierung und nach Erstellung von
                    neuen Benutzern werden nun Mails im passenden Design und mit
                    passendem Absender verschickt.
                  </li>
                </ul>
                <h2 class="mt-5 mb-3">Fixes (Fehlerbehebungen):</h2>
                <ul>
                  <li>
                    Diverse Fehlerbehebungen
                  </li>
                </ul>
                <v-divider class="my-3"></v-divider>
                <h1 class="my-3">
                  Release Version 2.2
                  <small>(03. Dezember 2020)</small>
                </h1>
                <h2 class="mt-5 mb-3">Neue Funktionen:</h2>
                <ul>
                  <li>
                    Zuschauerkontrolle: Es gibt von nun an ein neues Dashboard,
                    wo man auf einen Blick alle wichtigen Daten zu den
                    anwesenden Personen auf dem Sportgelände einsehen kann. Es
                    können z.B. über das Dashboard manuell Zuschauer erfasst
                    werden und einer derzeit stattfindenden Veranstaltung
                    zugeordnet werden. Zudem sieht man alle anwesenden Personen
                    auf dem Sportgelände - und zwar Veranstaltungsübergreifend.
                  </li>
                  <li>
                    Schiedsrichter-Registrierung: Es können nun auch bei Spielen
                    Schiedsrichter erfasst werden.
                  </li>
                  <li>
                    Neue Leichtathletik-Funktionen: Es wurde ein für die
                    Leichtathletik ein Wettkampf-Modul erstellt, über welches
                    nun auch mehrtätige Veranstaltungen besser erfasst werden
                    können.
                  </li>
                </ul>
                <v-divider class="my-3"></v-divider>
                <h1 class="my-3">
                  Release Version 2.0.1
                  <small>(23. Oktober 2020)</small>
                </h1>
                <h2 class="mt-5 mb-3">Neue Funktionen:</h2>
                <ul>
                  <li>
                    Auswertungsfunktion für Vorstände: Es kann nun die
                    Anwesenheit von bestimmten Personen gesucht werden. Es
                    werden dann passende Anwesenheitslisten generiert, die alle
                    Personen enthalten, die mit der gesuchten Person in Kontakt
                    gekommen sein können.
                  </li>
                  <li>
                    CSV Import: Es besteht nun die Möglichkeit Teammitglieder
                    über eine CSV-Datei zu importieren. Die Vorlage der
                    CSV-Datei ist für die exportierten Teamlisten aus
                    "DFBnet-Verein" optimiert worden.
                  </li>
                </ul>
                <h2 class="mt-5 mb-3">Fixes (Fehlerbehebungen):</h2>
                <ul>
                  <li>
                    Diverse Fehlerbehebungen
                  </li>
                </ul>
                <v-divider class="my-3"></v-divider>
                <h1 class="my-3">
                  Release Version 2.0
                  <small>(20. Oktober 2020)</small>
                </h1>
                <h2 class="mt-5 mb-3">Neue Funktionen:</h2>
                <ul>
                  <li>
                    Überarbeitung der Navigation und des Layouts
                  </li>
                  <li>
                    Vereine können jetzt mehrere Sportanlagen mit separaten
                    QR-Codes erstellen
                  </li>
                  <li>
                    Es wurde eine neue Veranstaltungsart hinzugefügt:
                    Versammlungen
                  </li>
                  <li>
                    Trainer können nun die Namen der anwesenden Zuschauer
                    einsehen. Allerdings können sie nach wie vor keine
                    Kontaktdaten einsehen oder bearbeiten.
                  </li>
                  <li>
                    Beim Login muss man nun bewusst die Option "Eingeloggt
                    bleiben" wählen. Ansonsten wird man automatisch mit
                    Schließen der Seite ausgeloggt.
                  </li>
                  <li>
                    Nachdem sich ein Zuschauer für eine Veranstaltung angemeldet
                    hat, wird nun auch eine kleine Übersicht mit Hygiene-Regeln
                    angezeigt. Diese Hygiene-Regeln können derzeit noch nicht
                    konfiguriert werden. Ein mit dieser Funktion ist in Planung.
                  </li>
                </ul>
                <h2 class="mt-5 mb-3">Fixes (Fehlerbehebungen):</h2>
                <ul>
                  <li>
                    Diverse Fehlerbehebungen
                  </li>
                </ul>
                <v-divider class="my-3"></v-divider>
                <h1 class="my-3">
                  Release Version 1.2.0
                  <small>(09. Oktober 2020)</small>
                </h1>
                <h2 class="mt-5 mb-3">Neue Funktionen:</h2>
                <ul>
                  <li>
                    Löschen von mehreren Terminen gleichzeitig möglich (Nur
                    Vorstände / Administratoren)
                  </li>
                  <li>
                    Beim Löschen eines Teams können nun auch alle zugehörigen
                    Veranstaltungen gelöscht werden. Zudem wird bei den Trainern
                    die Zuweisung auch automatisch entfernt.
                  </li>
                  <li>
                    Gastteam-Erfassung: Es können nun bei Spielen auch
                    Gästeteams erfasst werden
                  </li>
                </ul>
                <h2 class="mt-5 mb-3">Fixes (Fehlerbehebungen):</h2>
                <ul>
                  <li>
                    Löschen von Vereinsnutzern wieder möglich
                  </li>
                  <li>
                    Namensanzeige in verschiedenen Bereichen
                  </li>
                </ul>
                <v-divider class="my-3"></v-divider>
                <h1 class="my-3">
                  Release Version 1.1.0
                  <small>(01. Oktober 2020)</small>
                </h1>
                <h2 class="mt-5 mb-3">Neue Funktionen:</h2>
                <ul>
                  <li>
                    Anlegen von Serienterminen (Nur für die Veranstaltungsart
                    "Training" möglich)
                  </li>
                  <li>
                    "Passwort vergessen" - Funktion beim Login
                  </li>
                  <li>
                    Trainern können mehrere Mannschaften zugewiesen werden
                  </li>
                  <li>
                    Zuschauer-Erfassung: Es können mehrere Personen aus einem
                    Haushalt erfasst werden
                  </li>
                </ul>
                <h2 class="mt-5 mb-3">Fixes (Fehlerbehebungen):</h2>
                <ul>
                  <li>
                    Bestehende Zuschauer-Accounts können nun zu "Trainer" bzw.
                    "Vorstand" hochgestuft werden
                  </li>
                  <li>
                    Falsche Anzeige von Kontaktdaten und Besuche bei
                    Vereinsaccounts wurde korrigiert
                  </li>
                </ul>
                <v-divider class="my-3"></v-divider>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <v-container
      fluid
      style="
        position: fixed;
        bottom: 0;
        padding: 0;
        width: 100vw;
        background-color: #009640;
        border-top: 5px solid #ffffff;
        padding-bottom: env(safe-area-inset-bottom);
      "
      v-if="$vuetify.breakpoint.mdAndDown"
    >
      <v-row class="px-3 py-0">
        <v-col cols="6" md="9" lg="10" align-self="center" class="my-0 py-1">
          <span class="font-weight-light">
            Gemeinsam für
            <br />
            unsere Gesundheit.
          </span>
        </v-col>
        <v-col
          cols="6"
          md="3"
          lg="10"
          align-self="center"
          class="text-left my-0 py-0"
        >
          <v-img
            contain
            src="/img/AOK_Logo.png"
            style="width: 70%; margin-left: auto;"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Menubar from '../components/public/nav'
import UserMenu from '../components/nav/nav'
import ZuschauerMenu from '../components/zuschauer/nav'
import SchiriMenu from '../components/schiedsrichter/nav'
import { mapGetters } from 'vuex'

export default {
  name: 'Release Notes',
  components: {
    Menubar,
    UserMenu,
    ZuschauerMenu,
    SchiriMenu,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
  },
}
</script>
